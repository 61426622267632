import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useSDK } from "@metamask/sdk-react";
import { flipContractAbi, kwakAbi } from "./consts/abis";
import { contractAddress, kwakTokenAddress } from "./consts/contracts";
import { ONEFINITY_CHAIN_ID } from "./consts/onefinity";

import {
  Button,
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  Divider,
} from "@mui/material";
import "./App.css";

function App() {
  const { sdk, provider } = useSDK();
  const [contract, setContract] = useState(null);
  const [kwakContract, setKwakContract] = useState(null);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [isFlipping, setIsFlipping] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const [oneBalance, setOneBalance] = useState("0");
  const [kwakBalance, setKwakBalance] = useState("0");
  const [userOneBalance, setUserOneBalance] = useState("0");
  const [userKwakBalance, setUserKwakBalance] = useState("0");
  const [betAmount, setBetAmount] = useState("0.01");
  const [loading, setLoading] = useState(false);
  const [betCurrency, setBetCurrency] = useState("ONE");
  const [maxBetOne, setMaxBetOne] = useState("0");
  const [maxBetKwak, setMaxBetKwak] = useState("0");
  const [result, setResult] = useState(null);
  const [buttonLabel, setButtonLabel] = useState("Connect");
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [showResult, setShowResult] = useState(false);

  useEffect(() => {
    if (sdk) {
      sdk.on("chainChanged", handleChainChanged);
      sdk.on("accountsChanged", handleAccountsChanged);
    }

    return () => {
      if (sdk) {
        sdk.removeListener("chainChanged", handleChainChanged);
        sdk.removeListener("accountsChanged", handleAccountsChanged);
      }
    };
  }, [sdk]);

  useEffect(() => {
    if (
      buttonLabel === "Flip Coin" &&
      (!selectedCurrency ||
        !selectedAmount ||
        (selectedCurrency === "ONE" &&
          parseFloat(selectedAmount) > parseFloat(maxBetOne)) ||
        (selectedCurrency === "KWAK" &&
          parseInt(selectedAmount) > parseInt(maxBetKwak)))
    ) {
      setInfoMessage("Please choose a token and an amount below the max bet.");
    } else {
      setInfoMessage("");
    }
  }, [buttonLabel, selectedCurrency, selectedAmount, maxBetOne, maxBetKwak]);

  const handleChainChanged = async () => {
    resetState();
    setButtonLabel("Connect");
  };

  const handleAccountsChanged = (accounts) => {
    if (accounts.length === 0) {
      resetState();
      setErrorMessage("Please connect to MetaMask.");
      setButtonLabel("Connect");
    } else {
      setCurrentAccount(accounts[0]);
      setButtonLabel("Check Network");
    }
  };

  const resetState = () => {
    setContract(null);
    setKwakContract(null);
    setErrorMessage("");
    setInfoMessage("");
    setResult(null);
    setShowResult(false);
    setOneBalance("0");
    setKwakBalance("0");
    setUserOneBalance("0");
    setUserKwakBalance("0");
    setMaxBetOne("0");
    setMaxBetKwak("0");
    setSelectedCurrency(null);
    setSelectedAmount(null);
    setIsCorrectNetwork(false);
    setCurrentAccount(null);
  };

  const connectWallet = async () => {
    // Vérification si MetaMask est installé
    if (typeof window.ethereum === "undefined") {
      return;
    }

    if (!sdk) {
      // setErrorMessage("MetaMask is required to use this feature!");
      return;
    }

    try {
      const accounts = await sdk.connect();
      const connectedAccount = accounts[0];
      setCurrentAccount(connectedAccount);

      const tempProvider =
        sdk.provider || new ethers.providers.Web3Provider(window.ethereum);
      const signer = tempProvider.getSigner();

      const isNetworkSelected = await checkNetworkSelection();
      if (!isNetworkSelected) {
        setButtonLabel("Switch to OneFinity Network");
        setInfoMessage("OneFinity will be added if necessary.");
        return;
      }

      const coinFlipContract = new ethers.Contract(
        contractAddress,
        flipContractAbi,
        signer
      );
      const kwakInstance = new ethers.Contract(
        kwakTokenAddress,
        kwakAbi,
        signer
      );

      // Mettre à jour l'état avec les contrats
      setContract(coinFlipContract);
      setKwakContract(kwakInstance);

      //   console.log("Flip contract instance:", coinFlipContract);
      //   console.log("KWAK contract instance:", kwakInstance);

      // Mettre à jour les balances après la connexion, en passant les contrats directement
      await updateBalances(coinFlipContract, kwakInstance);
      setButtonLabel("Flip Coin");
      setInfoMessage("");
    } catch (error) {
      console.error("Error connecting to MetaMask:", error);
      setErrorMessage("An error occurred while connecting. Please try again.");
    }
  };

  const updateBalances = async (coinFlipContract, kwakInstance) => {
    // console.log("Contract:", coinFlipContract);
    // console.log("kwakContract:", kwakInstance);
    // console.log("Provider:", provider);

    if (!coinFlipContract || !kwakInstance || !provider) {
      console.error("Contract, kwakContract, or provider is not available");
      return;
    }

    try {
      // Convertir le provider en Web3Provider d'Ethers.js si ce n'est pas déjà fait
      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = ethersProvider.getSigner();
      const address = await signer.getAddress();

      // Récupérer les balances du smart contract
      const oneBalance = await coinFlipContract.getOneBalance();
      const kwakBalance = await coinFlipContract.getKwakBalance();
      setOneBalance(ethers.utils.formatEther(oneBalance));
      setKwakBalance(ethers.utils.formatEther(kwakBalance));

      // Récupérer le solde de l'utilisateur en ONE
      const userBalanceWei = await signer.getBalance();
      if (userBalanceWei) {
        setUserOneBalance(ethers.utils.formatEther(userBalanceWei));
      }

      // Récupérer le solde de l'utilisateur en KWAK
      const userKwakBalance = await kwakInstance.balanceOf(address);
      setUserKwakBalance(ethers.utils.formatEther(userKwakBalance));

      // Récupérer les mises maximales
      const maxBetOneValue = await coinFlipContract.getMaxBet(true); // true pour ONE
      const maxBetKwakValue = await coinFlipContract.getMaxBet(false); // false pour KWAK
      setMaxBetOne(ethers.utils.formatEther(maxBetOneValue));
      setMaxBetKwak(ethers.utils.formatEther(maxBetKwakValue));
    } catch (error) {
      console.error("Error fetching balances:", error);
    }
  };

const flipCoin = async () => {
  if (!contract || !kwakContract) {
    setErrorMessage("Contracts are not initialized.");
    return;
  }

  try {
    setLoading(true);
    setIsFlipping(true);

    // Convertir le provider en Web3Provider d'Ethers.js
    const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = ethersProvider.getSigner();
    const address = await signer.getAddress();

    const isOne = selectedCurrency === "ONE";
    let betAmountWei;

    if (isOne) {
      // Convertir le montant en Wei (Ether) si la devise est ONE
      betAmountWei = ethers.utils.parseEther(selectedAmount);
    } else {
      // Récupérer les décimales du token KWAK depuis le contrat
      // Convertir le montant en unités de KWAK en utilisant les décimales correctes
      betAmountWei = ethers.utils.parseUnits(selectedAmount, 18);
    }

    if (isOne) {
      const balance = await signer.getBalance();
      if (balance.lt(betAmountWei)) {
        setErrorMessage("Insufficient ONE balance.");
        setIsFlipping(false);
        return;
      }
    } else {
      const userKwakBalance = await kwakContract.balanceOf(address);
      if (ethers.BigNumber.from(userKwakBalance).lt(betAmountWei)) {
        setErrorMessage("Insufficient KWAK balance.");
        setIsFlipping(false);
        return;
      }

      // Approver l'utilisation de KWAK pour le montant exact
      const approvalTx = await kwakContract
        .connect(signer)
        .approve(contractAddress, betAmountWei);
      await approvalTx.wait(); // Attendre la confirmation de l'approbation

      console.log(`Approved ${selectedAmount} KWAK`);
    }

    // Appeler la fonction flip du contrat CoinFlip avec le bon montant
    const tx = await contract.connect(signer).flip(isOne, {
      value: isOne ? betAmountWei : 0,
    });
    setInfoMessage("Transaction sent. Waiting for confirmation...");
    const receipt = await tx.wait();
    const flipEvent = receipt.events?.find((e) => e.event === "Flip");

    if (flipEvent) {
      const won = flipEvent.args.won;
      const resultMessage = won ? "Win" : "Lost";
      setResult(resultMessage);

      // Masquer le message d'info puisque le résultat est déterminé
      setInfoMessage("");

      // Mettre à jour le bouton pour permettre de rejouer
      setButtonLabel("Play Again");
    } else {
      setResult("Flip completed, but result not found.");
      setInfoMessage("No result event detected.");
    }

    
  } catch (error) {
    console.error("Error flipping coin:", error);

    // Vérification de l'erreur pour déterminer le message à afficher
    if (error.code === 4001) {
      setErrorMessage("Transaction was rejected by the user.");
    } else if (error.code === -32603) {
      setErrorMessage("Transaction failed due to a smart contract issue.");
    } else if (error.message.includes("insufficient funds")) {
      setErrorMessage("Insufficient funds to complete the transaction.");
    } else if (error.message.includes("transaction failed")) {
      setErrorMessage("Transaction failed, try again.");
    } else {
      setErrorMessage("An error occurred during the coin flip.");
    }
  } finally {
    setLoading(false);
    setIsFlipping(false);
  }
};



  const checkNetworkSelection = async () => {
    try {
      const chainId = await provider.request({ method: "eth_chainId" });
      const correct = chainId.toLowerCase() === ONEFINITY_CHAIN_ID;
      setIsCorrectNetwork(correct);

      return correct;
    } catch (error) {
      console.error("Error checking network selection:", error);
      setIsCorrectNetwork(false);
      return false;
    }
  };

  const switchNetwork = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      setErrorMessage("MetaMask is not installed or not accessible.");
      return;
    }

    try {
      // Tentez d'ajouter le réseau directement
      await addNetwork(); // Appelez addNetwork

      // Vérifiez ensuite si le réseau a été ajouté
      const newChainId = await ethereum.request({
        method: "eth_chainId",
      });

      // Essayez de passer au réseau
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: ONEFINITY_CHAIN_ID }],
      });
    } catch (error) {
      if (error.code === 4902) {
        setErrorMessage("The network was not recognized. Please try again.");
      } else {
        setErrorMessage("An error occurred while switching networks.");
      }
    }
  };

  const addNetwork = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      setErrorMessage("MetaMask is not installed or not accessible.");
      return;
    }

    try {
      const params = [
        {
          chainId: ONEFINITY_CHAIN_ID,
          chainName: "OneFinity",
          rpcUrls: ["https://testnet-rpc.onefinity.network"],
          nativeCurrency: {
            name: "ONE",
            symbol: "ONE",
            decimals: 18,
          },
          blockExplorerUrls: ["https://explorer.onefinity.network"],
        },
      ];

      // Tentative d'ajout du réseau
      await ethereum.request({
        method: "wallet_addEthereumChain",
        params: params,
      });
      setInfoMessage("Network added. Please connect again.");
      setButtonLabel("Connect");
    } catch (addError) {
      console.error("Failed to add network:", addError);
      setErrorMessage("Failed to add the network. Please try again.");
    }
  };

  const disconnectWallet = () => {
    resetState();
    setButtonLabel("Connect"); // Remettre le bouton à Connect
    setInfoMessage(""); // Effacer le message d'information
  };

  const playAgain = async () => {
    try {
      resetState();
      await connectWallet();
    } catch (error) {
      console.error("Error during play again:", error);
      setErrorMessage("An error occurred. Please try again.");
    }
  };


  const handleButtonClick = async () => {
    if (buttonLabel === "Connect") {
      await connectWallet();
    } else if (buttonLabel === "Switch to OneFinity Network") {
      await switchNetwork();
    } else if (buttonLabel === "Flip Coin") {
      await flipCoin();
    } else if (buttonLabel === "Disconnect") {
      disconnectWallet();
    }
  };

  return (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
      <Box>
        {result ? (
          // Affichage du résultat avec image correspondante
          <>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "50px", textAlign: "center" }}
            >
              <Typography
                variant="h6"
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                {result === "Win"
                  ? "Congratulations, You won!"
                  : "Sorry, You lost!"}
              </Typography>

              <img
                src={`/assets/${result}.png`}
                alt={result}
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  marginBottom: "20px",
                }}
              />

              <Button
                className="font-personal-app"
                variant="contained"
                color="primary"
                onClick={playAgain}
                style={{
                  marginTop: "20px",
                  backgroundColor: "green",
                  color: "white",
                  width: "200px",
                }}
              >
                Play Again
              </Button>

              <Button
                className="font-personal-app"
                variant="outlined"
                color="secondary"
                onClick={disconnectWallet}
                style={{
                  marginTop: "20px",
                  color: "red",
                  borderColor: "red",
                  width: "200px",
                }}
              >
                Disconnect
              </Button>
            </Box>
          </>
        ) : (
          <>
            {currentAccount && (
              <Typography
                className="font-personal-app"
                variant="body1"
                style={{ marginBottom: "20px" }}
              >
                Connected with address: {currentAccount}
              </Typography>
            )}

            {isCorrectNetwork ? (
              <>
                <Typography
                  className="font-personal-app"
                  variant="body1"
                  style={{ marginBottom: "10px" }}
                >
                  Your Balance: {parseFloat(userOneBalance).toFixed(4)} ONE /{" "}
                  {parseFloat(userKwakBalance).toFixed(2)} KWAK
                </Typography>
                <Typography
                  className="font-personal-app"
                  variant="body1"
                  style={{ marginBottom: "20px" }}
                >
                  Max Bet: {parseFloat(maxBetOne).toFixed(4)} ONE /{" "}
                  {parseFloat(maxBetKwak).toFixed(2)} KWAK
                </Typography>
              </>
            ) : (
              <Typography variant="body1" style={{ marginBottom: "20px" }} />
            )}

            {/* Afficher les boutons de sélection de devise uniquement si on est prêt à parier */}
            {buttonLabel === "Flip Coin" && (
              <>
                <Typography
                  className="font-personal-app"
                  variant="body1"
                  style={{ marginBottom: "10px", marginTop: "20px" }}
                >
                  <strong>Select Currency:</strong>
                </Typography>
                <Box display="flex" justifyContent="center" mb={2}>
                  <Button
                    className="font-personal-app"
                    variant={
                      selectedCurrency === "ONE" ? "contained" : "outlined"
                    }
                    color="primary"
                    onClick={() => setSelectedCurrency("ONE")}
                    style={{ marginRight: "10px" }}
                  >
                    ONE
                  </Button>
                  <Button
                    className="font-personal-app"
                    variant={
                      selectedCurrency === "KWAK" ? "contained" : "outlined"
                    }
                    color="primary"
                    onClick={() => setSelectedCurrency("KWAK")}
                  >
                    KWAK
                  </Button>
                </Box>
              </>
            )}

            {/* Afficher les montants uniquement si une devise est sélectionnée */}
            {selectedCurrency && buttonLabel === "Flip Coin" && (
              <>
                <Typography
                  className="font-personal-app"
                  variant="body1"
                  style={{ marginBottom: "10px", marginTop: "20px" }}
                >
                  <strong>Select Amount:</strong>
                </Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                  flexWrap="wrap"
                  mb={2}
                >
                  {(selectedCurrency === "ONE"
                    ? ["0.1", "0.2", "0.5", "1", "1.5", "2"]
                    : ["100", "200", "500", "1000", "1500", "2000"]
                  ).map((amount) => (
                    <Button
                      key={amount}
                      className="font-personal-app"
                      variant={
                        selectedAmount === amount ? "contained" : "outlined"
                      }
                      color="primary"
                      onClick={() => setSelectedAmount(amount)}
                      style={{ margin: "5px" }}
                    >
                      {amount}
                    </Button>
                  ))}
                </Box>
              </>
            )}

            <Button
              className="font-personal-app"
              variant="contained"
              color="primary"
              onClick={handleButtonClick}
              disabled={
                loading ||
                (buttonLabel === "Flip Coin" && !selectedCurrency) ||
                (buttonLabel === "Flip Coin" && !selectedAmount) ||
                (buttonLabel === "Flip Coin" &&
                  selectedCurrency === "ONE" &&
                  parseFloat(selectedAmount) > parseFloat(maxBetOne)) ||
                (buttonLabel === "Flip Coin" &&
                  selectedCurrency === "KWAK" &&
                  parseInt(selectedAmount) > parseInt(maxBetKwak))
              }
              style={{
                marginBottom: "20px",
                backgroundColor:
                  buttonLabel === "Flip Coin"
                    ? loading ||
                      !selectedCurrency ||
                      !selectedAmount ||
                      (selectedCurrency === "ONE" &&
                        parseFloat(selectedAmount) > parseFloat(maxBetOne)) ||
                      (selectedCurrency === "KWAK" &&
                        parseInt(selectedAmount) > parseInt(maxBetKwak))
                      ? "gray"
                      : "green"
                    : "blueviolet",
                color: "white",
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                buttonLabel
              )}
            </Button>

            {/* Affichage des messages d'erreur et d'information */}
            {errorMessage && (
              <Typography
                variant="body1"
                color="error"
                style={{ marginBottom: "20px" }}
              >
                {errorMessage}
              </Typography>
            )}
            {infoMessage && (
              <Typography
                variant="body1"
                color="primary"
                style={{ marginBottom: "20px" }}
              >
                {infoMessage}
              </Typography>
            )}
          </>
        )}
      </Box>
    </Container>
  );


}

export default App;
